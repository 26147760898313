.token,.homeimage{
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
model-viewer {

}
.iconimage{
  height: 40px;
}
.container{
  margin-top: 20px;
  margin-bottom: 80px;
}
.navbar-brand{
  margin: 5px;
}
.profile{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
td {
  white-space: normal !important; 
  word-wrap: break-word;  
}
table {
  table-layout: fixed;
}

.rounded {
  border-radius: 15px !important;
}

/* navbar-adj */
.navbar-adj{height:80px}
/* nft-detail */
.nft-detail-adj .table-adj tr th, .table-adj tr td {padding: 15px;}
.nft-detail-adj .table-adj tr th{width: 20%; color:#FFF;}
.nft-detail-adj .table-adj tr{border: 1px solid #80808052;}
.nft-detail-adj .table-adj {}
.nft-detail-adj:hover .table-adj:hover {
  color:#fff;
}

table {
  border-collapse:separate;
  border: solid #333 1px !important;
  border-radius:20px;
  -moz-border-radius:20px;
}

thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
  border-radius: 15px 0 0 0;
}
thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 15px;
}

th {
  border-top: none !important;
}

td:first-child, th:first-child {
   border-left: none !important;
}

.homeButton {
  padding-left: 35px;
  padding-right: 35px;
  font-size:32px;
  font-weight:100;
  border-radius: 20px !important;
  outline: none;
}

/* home */
/* .home-adj .wrapper,.form-wrapper {border: 1px solid #8080802b;height: fit-content;box-shadow:0 0px 20px -3px #77777721}
.home-adj .heading-container h1,.form-wrapper h3 {padding: 0.3em 1em 0.3em 0.3em;background: aliceblue;font-size: 36px;} */
.home-adj,.explore-adj,.my-collect-adj,.mint-token-adj,.create-mint-adj,.nft-detail-adj{
  padding:3rem 1.5rem;
  border: 1px solid #21252914;
  margin-top: -20px;
  border-radius: 15px;
  background-color: #111;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

.home-adj a {
  color: #FFF;
  text-decoration: none;
}

/* explore-adj */
.explore-adj .card,.my-collect-adj .card{box-shadow:0 0px 20px -3px #00000077;}
.explore-adj .card:hover,.my-collect-adj .card:hover{box-shadow:0 0px 25px -2px #6697ff77;}
.explore-adj a,.my-collect-adj a{text-decoration: none;color:#FFF;}


/* .token {width: 100%;} */

/* footer */
.footer-adj,.navbar-adj {background-color: #0021423a !important;color: #fff;}
.footer-adj p {margin-top: 1rem;margin-bottom: 1rem;}

.max-250{
  height: 250px;
  width: 100%;
  position: relative;
}

.max-150 {
  height: 150px;
  width: 100%;
  position: relative;
}

.max-300{
  height: 300px;
  width: 100%;
}

.text-title {
  text-transform: uppercase;
  color: rgb(255 255 255);
  font-size: 14px;
  font-weight: 600;
  font-family: Avenir Next,Avenir,Avenir Next Local,sans-serif!important;
  line-height: 1.5;
  padding: 2px 8px;
  background: #3050dc;
  width: fit-content;
  margin: 10px;

}
.head-title {
  display: inline-block;
  background: #00398f;
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
}

.intro {
  background: #00000096;
  text-align: center;
  z-index: 5000;
}

/* .intro:after {
  content:'';
  position: absolute;
  width: 0;
  height: 0;
  margin-top: 9px;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 60px solid #00000096;
  left: 50%;
  margin-left: -20px;
} */

.wrapper {
  display: grid;
}
body {
  background-color: #000;
  background-image: url("bgmain.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  font-family: 'Lato', sans-serif;
}
.btn-primary
{
  background-color:#1557b413;
  border-radius:5px !important;
}
.buybtn {
  box-shadow: 0px 0px 10px #0054ca !important;
  border-radius: 15px !important;
  background-color: #1557b4b6 !important;
}
.eth-class{
  height: 26px;
  margin: 0px 5px;
  padding-bottom: 5px;
}
h2 {
  color: #FFF;
}

.card {
  border-radius: 15px !important;
  background-color: #1d1e21 !important;
  color: #FFF !important;
}

.table {
  color: #c0e0ff;

}

._loading_overlay_overlay {   position: fixed; }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}

input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0; 
}

input[type="text"], input[type="text"]:focus, input[type="file"], input[type="file"]:focus, select[type="text"], select[type="text"]:focus, textarea[type="text"], textarea[type="text"]:focus {
  background-color:#222;
  color: #FFF;
  border-radius:5px;
  padding:5px;
  border-color: #333;
  outline: none;
}

input[type="number"], input[type="number"]:focus, input[type="file"], input[type="file"]:focus, select[type="number"], select[type="number"]:focus, textarea[type="number"], textarea[type="number"]:focus {
  background-color:#222;
  color: #FFF;
  border-radius:5px;
  padding:5px;
  border-color: #333;
  outline: none;
}

#fileupload {
  color: white;
  background-color: #222;
  border-color: #333;
  text-align:left;
  display: inline-block;
}

.nav-link {
  text-align:center;
}

.bg-dark {
  background-color: #111 !important;
}

body::-webkit-scrollbar {
  width: 15px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #000;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #1557b4;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #000;  /* creates padding around scroll thumb */
}

.nav {
  justify-content: center;
}

.nav-tabs {
  border-bottom: 1px solid #313131;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #71b8ff !important;
  background-color: #000 !important;
  border-color: #313131 #313131 #313131 !important;
}

.nav-tabs .nav-link:hover {
  color: #71b8ff !important;
  background-color: #000 !important;
  border-color: #313131 #313131 #313131 !important;
}

.colltxt {
  font-size: 16px;
  color: #636363 !important;
}

.idbadge {
  margin-bottom: 5px;
  position: absolute;
  float: left;
  left: 0px;
  top: 15px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 5px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  color: #FFF !important;
  background-color: #0054ff;
  font-size: 13px;
  box-shadow: 1px 2px 5px #00000059;
}

@media screen and (min-width: 601px) {
  .addy {
    font-size: 16px;
  }
  .container {
    max-width: 90% !important;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .addy {
    font-size: 12px;
  }
  
  .mintbtn {
    margin-right: 0px !important;
  }
  .fouro {
    font-size: 158px !important;
  }

  .rainbowtxt1 {
    font-size: 33px !important;
  }

  .rainbowtxt2 {
    font-size: 33px !important;
  }

  .rainbowtxt3 {
    font-size: 33px !important;
  }

  .dropdown {
    margin-right: 0px !important;
  }
  .dropdown-menu {
    right: 0;
    left: auto;
    color: #FFF;
    background-color: #000;
    width:100% !important;
    border-radius: 0px !important;
    padding: 30px !important;
  }
  #drop-area h3 {
    margin-top: -5px !important;
  }
  .container {
    max-width: 100% !important;
  }
}

.dropdown .bal {
  font-size:19px;
  text-align: center;
}

svg {
  /* background: rgb(0, 0, 0);
  border: 2px solid white;
  border-radius: 50%; */
}

.mainfoot {
  background-color: #000 !important;
  min-height: 350px;
  width: 100%;
  padding: 20px;
} 

.foottxt a {
  color: #FFF;
  text-decoration: none;
  font-size:15px;
  font-weight: bold;
  margin-left:10px;
  margin-right:10px;
  margin-bottom:35px;
}

.avaimg {

  display: inline-block;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown {
  margin-right: 30px;
  padding: 10px;
}

.dropdown-menu {
  color: #FFF;
  background-color: #000;
  width:350px;
  border-radius: 15px;
  padding: 30px;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-item {
  color: #FFF;
}

.dropdown-item:hover {
  color: #fff;
  background-color: #00398F;
  border-radius: 5px;
}

#drop-area {
  border: 3px dashed #ccc;
  background-color: rgb(22, 22, 22) !important;
  border-radius: 20px;
  width: 90%;
  height: 200px;
  margin: 10px auto;
  padding: 20px;
}
#drop-area.highlight {
  border-color: rgb(2, 113, 218);
  background-color: #000 !important;
}
#drop-area h3 {
  margin-top:25px;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
#fileElem {
  display: none;
}

model-viewer {
  --poster-color: #000;
}

.mintbtn {
  background: rgb(4,15,30);
  background: radial-gradient(circle, rgba(34,123,219,1) 0%, rgba(34,123,219,1) 50%, rgba(31,64,131,1) 100%);
  border-radius: 10px;  
  border: none;
  padding: 15px;
  font-weight: 600;
  margin-top: 3px;
  margin-right:15px;
  padding-left: 25px;
  padding-right: 25px;
  color: #FFF;
  font-weight: bold;
}

.mintbtn:hover {
  color: #FFF;
  transform: scale(1.05);
  transition: 0.5s;
}

.swapbtn {
  background: rgb(4,15,30);
  background: radial-gradient(circle, rgba(34,123,219,1) 0%, rgba(34,123,219,1) 50%, rgba(31,64,131,1) 100%);
  border-radius: 10px;  
  border: none;
  padding: 15px;
  font-weight: 600;
  margin-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  color: #FFF;
  font-weight: bold;
}

.swapbtn:hover {
  color: #FFF;
  transform: scale(1.05);
  transition: 0.5s;
}

.like {
  color: #aaa;
}

.like:hover {
  color: #666;
}

.liked {
  color: #ff4343;
}

.ice {
  color: #aaa;
}

.ice:hover {
  color: #666;
}

.iced {
  color: #4992ff;
}

.mymodal {
  top: 50%;
  left: 50%;
  right: auto;
  background-color: #000;
  border-color: #333;
  border-radius: 15px;
  color: #FFF;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.btn-outline-light {
  margin-top: 6px;
  font-size: 18px;
  border: 1px solid transparent;
}

.categorynav {
  color: #FFF !important;
  padding: 9px !important;
  border: 1px solid transparent;
  margin: 0px !important;
  font-size: 18px;
  border-radius: 15px;
  margin-bottom: 5px !important;
}

.categorynav:hover {
  color: #000 !important;
  background-color: #FFF;
}

/* .container{
  display: flex;
  list-style: none;
  justify-content: center;
}

.page{
	padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
    color: #eee;
}

.disabled{
    cursor: not-allowed;
    
}

.active{
	border: 2px solid rgb(0, 132, 255);
  font-weight: bold;
  color: #fff;
}

.previous{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}

.break{
    padding: 10px;
}

.next{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
} */

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span {
  color: #34495E;
  padding: 0.5rem 0.25rem;
}

.checkbox {
  height: 21px;
  width: 21px;
  margin-top:15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #000;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: hsl(214, 69%, 49%);
  cursor: pointer;
}

.checkbox:checked {
  border: 1px solid hsl(214, 69%, 49%);
  background-color: #000000;
}

.checkbox:checked::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: hsl(214, 69%, 49%);
  position: absolute;
  left: 0.2rem;
  top: -0.1rem;
}

.checkbox:active {
  border: 2px solid #34495E;
}

.rainbowtxt1 {
  font-size: 66px;
  color: #7db6e0;
  font-weight: bold;
  font-family: "Helvetica";
}
.rainbowtxt2 {
  font-size: 66px;
  font-weight: bold;
  font-family: "Helvetica";
  background: linear-gradient(to right, #7db6e0, #0B5ED7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rainbowtxt3 {
  font-size: 66px;
  font-weight: bold;
  font-family: "Helvetica";
  background: linear-gradient(to right, #0B5ED7, #7db6e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.liquid {
  flex: 1;
  padding: 25px 50px;
  margin: 25px;
  border-radius: 999px;
  border: 5px solid $color;
  white-space: nowrap;
  color: #fff;
  background: transparent;
  background-image: url('nav-bg-fill-blue.png');
  background-repeat: repeat-x;
  background-position: 0 -200%;
  transition: 1.5s ease;
}

.liquid:hover {
  background-position: 500% 100%;
}

.liquid2 {
  flex: 1;
  padding: 15px 40px;
  margin: 25px;
  border-radius: 999px;
  border: 5px solid $color;
  white-space: nowrap;
  color: #fff;
  background: transparent;
  background-image: url('nav-bg-fill-blue.png');
  background-repeat: repeat-x;
  background-position: 0 -500%;
  transition: 1.5s ease;
}

.liquid2:hover {
  background-position: 500% 100%;
}

.Center {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.dropdown-divider {
  border-top: 1px solid rgb(189 189 189 / 15%);
}

.watermark { position: relative; }
.watermark::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: "SYFIN NFT PREVIEW"; font-size: 2.5rem; font-family: "Helvetica"; font-weight: bold; color: rgb(0, 134, 211); }

.mintinputs {
  padding: 15px !important;
}

.boxshadow {
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.45);
}

.card:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.fouro {
  font-size: 358px;
}